import { baseService } from "./baseService";

export class QuanLyDatVeService extends baseService {
  // constructor() {
  //   super();
  // }

  layChiTietPhongVe = (maLichChieu) => {
    return this.get(
      `/api/QuanLyDatVe/LayDanhSachPhongVe?MaLichChieu=${maLichChieu}`
    );
  };

  datVe = (thongTinDatVe) => {
    return this.post(`/api/QuanLyDatVe/DatVe`, thongTinDatVe);
  };

  taoLichChieu = (lichChieu) => {
    return this.post(`/api/QuanLyDatVe/TaoLichChieu`, lichChieu);
  };
}

export const quanLyDatVeService = new QuanLyDatVeService();
